<template>
  <div class='flex flex-row justify-start gap-x-4'>
    <div v-for='(selectableDate, index) in dates'
      :key='`sessionVotings-${selectableDate}`'
      class='program-date-selector standard-transition'
      :class='sessionVotingsDateSelectorActiveClass(index)'
      @click='selectDateIndex(index)'>
      <div class='uppercase font-light text-sm leading-4'>{{ monthString(selectableDate) }}</div>
      <div class='font-semibold text-3xl leading-4 my-2'>{{ dayString(selectableDate) }}</div>
      <div class='uppercase font-light text-sm leading-4'>{{ dayOfWeekString(selectableDate) }}</div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import dayjs    from 'dayjs'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'TabsForDate',
  components: {
  },
  props: {
    'dates': {
      type: Array,
      required: true,
    },
    'selectedIndex': {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data () {
    return {
      index: 0,
    }
  },
  watch: {
    selectedIndex: {
      handler: function (newVal) {
        this.index = newVal
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'dateLocaleString',
    ]),
  },
  methods: {
    monthString (date) {
      return dayjs(date, this.dateLocaleString).format('MMM')
    },
    dayString (date) {
      return dayjs(date, this.dateLocaleString).format('DD')
    },
    dayOfWeekString (date) {
      return dayjs(date, this.dateLocaleString).format('ddd')
    },
    sessionVotingsDateSelectorActiveClass (index) {
      return (this.index === index) ? 'is-active' : ''
    },
    selectDateIndex (index) {
      this.index = index
      this.$emit('select-date-index', index)
    },
  },
}
</script>

<style type='scss' scoped>
.program-date-selector {
  @apply bg-gray-50 rounded px-4 text-center py-4 cursor-pointer;
}

.program-date-selector:hover {
  background-color: var(--eventMainColor);
  color: #FFF;
}

.program-date-selector.is-active {
  @apply shadow-lg;
  background-color: var(--eventMainColor);
  color: #FFF;
}

</style>
