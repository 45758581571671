<template>
  <div 
    class='flex flex-row justify-start'
    :class='tabsContainerCssClass'
    :style='tabsContainerCssStyle'>
    <div v-for='tab in tabs'
      :key='`generic-tab-${tab.value}`'
      class='px-1 py-1 mr-4 border-b-2 border-transparent text-gray-600 font-light tracking-wide cursor-pointer'
      :class='overrideTabClass(tab.value)'
      :style='tabCssStyle'
      @click='selectTab(tab.value)'>
      {{tab.title}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabsGeneric',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    tabsContainerCssClass: {
      type: String,
      default: '',
    },
    tabsContainerCssStyle: {
      type: String,
      default: '',
    },
    tabCssClass: {
      type: String,
      default: '',
    },
    tabCssStyle: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      selectedTab: '',
    }
  },
  methods: {
    selectTab (tab) {
      this.selectedTab = tab
      this.$emit('select-tab', tab)
    },
    isSelectedTabClass (tab) {
      return (this.selectedTab === tab) ? 'selected-tab' : ''
    },
    overrideTabClass (tab) {
      return `${this.tabCssClass} ${this.isSelectedTabClass(tab)}`
    },
  },
  mounted () {
    this.selectedTab = this.tabs[0].value
  }
}
</script>

<style type='scss' scoped>
.selected-tab {
  @apply font-semibold;
  border-bottom-color: var(--eventMainColor);
  color: var(--eventMainColor);
}

</style>
